// 入口文件
// REACT
import React from "react";

import ReactDOM from "react-dom";
// 全局状态管理, GlobalContextProvider全局状态发布组件
import { GlobalContextProvider } from "./utils/globalContext";
// 引入自定log
import './utils/consoleDef'
// 全局路由
import Router from "./route";
// 全局主题组件
import GlobalTheme from "./theme/globalTheme";
// 默认样式表
import "./css/index.css";
import axiosRetry from 'axios-retry';
import axios from "axios";

// 引入listenMessage事件
import "./utils/listenMessage";
// 字体库
// import "./css/font.css";
// MAIN
ReactDOM.render(
  <GlobalContextProvider>
    <GlobalTheme>
      <Router />
      {/*<Test />*/}
    </GlobalTheme>
  </GlobalContextProvider>,
  document.getElementById("root")
);
