// 全屏广告控制规则
const vignetteShowRule = {
  pageJumpPosition: process.env.GOOGLE_VIGNETTE_POSITION?.split(",") || [
    "function_to_result",
    "home_function_tool_list",
    "home_nav_to_function",
    "home_menu_to_function",
    "home_step",
    "home_nav_to_subscribe",
    "function_tool_list",
  ],
};
/**
 * 全屏广告控制
 * @param {String} userSubState 用户订阅状态
 * @param {String} jumpPosition 跳转链接位置
 * @param {String} href 需要检测的路径,暂时不可用,没有路径限制
 * @returns {Boolean} isVignetteAllow 是否允许全屏广告
 */
export default function vignetteControl(userSubState, jumpPosition, href) {
  try {
    // 验证当前用户订阅状态是否允许全屏广告
    // 订阅用户将禁用全屏广告
    if (userSubState === "ACTIVE") {
      return false;
    }
    // 验证路由
    // const location = href ?? window.location.href;
    // 验证跳转触发点
    return vignetteShowRule.pageJumpPosition.findIndex((position) => {
      return position === jumpPosition;
    }) === -1
      ? false
      : true;
  } catch (e) {
    loge(e);
    return false;
  }
}
