// 全局路由管理文件
// REACT
import React from "react";
// ROUTER
import { Alert, Snackbar, Typography, Button, Box, Input } from "@mui/material";

// GLOBAL全局状态
import { GlobalContext } from "../utils/globalContext";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// COMPONENT
import Home from "../page/Home";
import Loading from "../page/toolpage/component/Loading";

// 懒加载路由
const Result = React.lazy(() => import("../page/Result"));
const CompressPDF = React.lazy(() =>
  import("../page/toolpage/CompressPDF")
);
const DeletePDF = React.lazy(() =>
  import("../page/toolpage/DeletePDF")
);
const ExcelToPDF = React.lazy(() =>
  import("../page/toolpage/ExcelToPDF")
);
const ImageToPDF = React.lazy(() =>
  import("../page/toolpage/ImageToPDF")
);
const WordToPDF = React.lazy(() =>
  import("../page/toolpage/WordToPDF")
);
const MergePDF = React.lazy(() => import("../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() =>
  import("../page/toolpage/PDFReader")
);
const PDFEdit = React.lazy(() => import("../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() => import("../page/toolpage/PDFEditor/PDFEditWorkSpace"));
const PDFToJpg = React.lazy(() => import("../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() =>
  import("../page/toolpage/RotatePDF")
);
const PDFToWord = React.lazy(() =>
  import("../page/toolpage/PDFToWord")
);
const Profile = React.lazy(() => import("../page/Profile/Profile"));
const ProfileContent = React.lazy(() =>
  import("../page/Profile/ProfileContent")
);
const SplitPDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/SplitPDF/SplitPDFWorkSpace")
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/MergePDF/MergePDFWorkSpace")
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/RotatePDF/RotatePDFWorkSpace")
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/DeletePDF/DeletePDFWorkSpace")
);
const ForgetPassWeb = React.lazy(() =>
  import("../page/component/User/ForgetPassWeb")
);
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../page/Profile/ResetPasswordByOldPassword")
);
const SubscribePage = React.lazy(() =>
  import("../page/subscribe/SubscribePage")
);
const MiddlePageOfConvert = React.lazy(() =>
  import("../page/toolpage/MiddlePageOfConvert")
);
const Support = React.lazy(() => import("../page/Support"));
const DownloadPage = React.lazy(() => import("../page/DownloadPage"));
export default function Router() {
  // 引入全局属性
  const { fileEncryption, setFileEncryption, snackInfo } =
    React.useContext(GlobalContext);

  const [open, setOpen] = React.useState(false);
  //文件加密弹出
  //关闭
  const handleClose = (event, reason) => {
    console.log("reason", reason);
    if (reason === "clickaway") {
      return;
    }
    setFileEncryption(false);
  };

  return (
    <BrowserRouter>
      {/* 路由模式 */}
      <Routes histody={history}>
        {/* 主页 */}
        <Route path="/" element={<Home />} />
        <Route path=":tag/" element={<Home />} />
        <Route path=":tag1/:tag2/" element={<Home />} />
        <Route
          path=":tag1/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/Convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/PDFEditWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEditWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/PDFEditWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEditWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path="/PDFEditor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/PDFEditor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/SplitPDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/SplitPDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/RotatePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/RotatePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/DeletePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/DeletePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/MergePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/MergePDFWorkSpace"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path="/CompressPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/CompressPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/CompressPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/MergePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/MergePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/MergePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/SplitPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/SplitPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/SplitPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/RotatePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/RotatePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/RotatePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/DeletePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/DeletePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/DeletePDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/PDFReader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/PDFReader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/PDFReader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path="/PDFToWord"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/PDFToWord"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/PDFToWord"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path="/WordToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/WordToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/WordToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/PPTToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/PPTToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/PPTToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/ExcelToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ExcelToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ExcelToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/PDFToJPG"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/PDFToJPG"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/PDFToJPG"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        <Route
          path="/ImageToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ImageToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ImageToPDF"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Share/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/:tag4/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path="/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag1/:tag2/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        {/* Support页面 */}
        <Route
          path="/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        {/* 忘记密码 */}
        <Route
          path="/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path="/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        <Route
          path="/:tag1/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        <Route
          path="/:tag1/:tag2/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        {/* 找不到页面 */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
      {/* 加密提示 */}
      <Snackbar
        sx={{
        }}
        open={fileEncryption}>
        <Alert variant="filled" severity="info" onClose={handleClose}>
          {snackInfo.title == 'File is encrypted' &&
            <Box>
              <Typography>Please Input:</Typography>
              <Input placeholder="PDF Password"
                sx={{
                  color: "#ffffff",
                  ":after": {
                    borderBottom: "2px solid #ffffff",
                  },
                  ":before": {
                    borderBottom: "2px solid #fffafa",
                  }
                }}
              />
              <Button variant="text">Confirm</Button>
            </Box>
          }
          {snackInfo.title != 'File is encrypted' && <Box>
            <Typography variant="body5" component="p">
              {snackInfo.title}
            </Typography>
            <Typography variant="caption">{snackInfo.message}</Typography>
          </Box>
          }
        </Alert>
      </Snackbar>
    </BrowserRouter>
  );
}
