// 工具类别
export const toolCategory = [
  { ID: 0, name: "Convert to PDF" },
  { ID: 1, name: "Convert from PDF" },
  { ID: 2, name: "Edit PDF" },
];
// 工具列表
export const toolList = [
  {
    ID: 0,
    categoryID: 0,
    key: "0-0-pdf2word",
    name: "PDFToWord",
    path: "/PDFToWord",
    domain: "pdf2word",
    icon: "icon_pdf_to_word.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_word_new.svg",
    color: "#3D99F5",
    inputFileType: ["pdf"],
    outputFileType: ["doc", "docx"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2word.name",
        default: "PDF to Word",
      },
      description: {
        key: "tool.pdf2word.description",
        default: "Quickly convert PDF files to easily editable Word files.",
      },
      title: {
        key: "tool.pdf2word.title",
        default: "PDF to Word",
      },
      subtitle: {
        key: "tool.pdf2word.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.pdf2word.tipTitle",
          default: ["How to", "Convert a PDF Document", "to Word"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2word.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2word.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2word.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2word.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2word.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  {
    ID: 1,
    categoryID: 1,
    key: "1-0-word2pdf",
    name: "WordToPDF",
    path: "/WordToPDF",
    domain: "word2pdf",
    icon: "icon_word_to_pdf.svg",
    beforeToolIcon: "icon_word_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#3D99F5",
    inputFileType: ["doc", "docx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.word2pdf.name",
        default: "Word to PDF",
      },
      description: {
        key: "tool.word2pdf.description",
        default: "Convert Word files to PDF files quickly and directly.",
      },
      title: {
        key: "tool.word2pdf.title",
        default: "Word to PDF",
      },
      subtitle: {
        key: "tool.word2pdf.subtitle",
        default: "Convert your Word to PDF",
      },
      tip: {
        title: {
          key: "tool.word2pdf.tipTitle",
          default: ["How to", "convert Word", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.word2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.word2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.word2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.word2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.word2pdf.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  {
    ID: 2,
    categoryID: 2,
    key: "2-2-compresspdf",
    name: "CompressPDF",
    path: "/CompressPDF",
    domain: "compresspdf",
    icon: "icon_compress_pdf.svg",
    beforeToolIcon: "icon_compress_pdf.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#F23030",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.compresspdf.name",
        default: "Compress PDF",
      },
      description: {
        key: "tool.compresspdf.description",
        default: "Quickly and directly reduce PDF file size.",
      },
      title: {
        key: "tool.compresspdf.title",
        default: "Compress PDF",
      },
      subtitle: {
        key: "tool.compresspdf.subtitle",
        default: "Reduce the size of your PDF online",
      },
      tip: {
        title: {
          key: "tool.compresspdf.tipTitle",
          default: ["How to", "compress", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.compresspdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.compresspdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.compresspdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.compresspdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  {
    ID: 3,
    categoryID: 1,
    key: "1-1-img2pdf",
    name: "ImageToPDF",
    path: "/ImageToPDF",
    domain: "img2pdf",
    icon: "icon_image_to_pdf.svg",
    beforeToolIcon: "icon_img.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#FFB700",
    inputFileType: [
      "avif",
      "cur",
      "jfif",
      "bmp",
      "gif",
      "jpg",
      "jpeg",
      "png",
      "svg",
      "webp",
    ],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.img2pdf.name",
        default: "Image to PDF",
      },
      description: {
        key: "tool.img2pdf.description",
        default: "Convert Image to PDF files quickly and directly.",
      },
      title: {
        key: "tool.img2pdf.title",
        default: "Image to PDF",
      },
      subtitle: {
        key: "tool.img2pdf.subtitle",
        default: "The best web app to convert Image to PDF",
      },
      tip: {
        title: {
          key: "tool.img2pdf.tipTitle",
          default: ["How to", "Convert Image", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.img2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.img2pdf.tip1.text",
              default:
                "Upload steps: Upload Image files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.img2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.img2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.img2pdf.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  {
    ID: 4,
    categoryID: 2,
    key: "2-4-mergepdf",
    name: "MergePDF",
    path: "/MergePDF",
    domain: "mergepdf",
    icon: "icon_merge.svg",
    beforeToolIcon: "icon_merge_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#7961F2",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.mergepdf.name",
        default: "Merge PDF",
      },
      description: {
        key: "tool.mergepdf.description",
        default:
          "Can quickly and directly merge multiple PDFs into one PDF file.",
      },
      title: {
        key: "tool.mergepdf.title",
        default: "Merge PDF",
      },
      subtitle: {
        key: "tool.mergepdf.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.mergepdf.tipTitle",
          default: ["How to", "merge", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.mergepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.mergepdf.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },
  {
    ID: 5,
    categoryID: 2,
    key: "2-3-splitpdf",
    name: "SplitPDF",
    path: "/SplitPDF",
    domain: "splitpdf",
    icon: "icon_split.svg",
    beforeToolIcon: "icon_split_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#7961F2",
    inputFileType: ["pdf"],
    outputFileType: ["pdf", "zip"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.splitpdf.name",
        default: "Split PDF",
      },
      description: {
        key: "tool.splitpdf.description",
        default: "Quickly and directly split a PDF into multiple PDF files.",
      },
      title: {
        key: "tool.splitpdf.title",
        default: "Split PDF",
      },
      subtitle: {
        key: "tool.splitpdf.subtitle",
        default: "An easy way to extract pages from PDF",
      },
      tip: {
        title: {
          key: "tool.splitpdf.tipTitle",
          default: ["How to", "split", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.splitpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  {
    ID: 6,
    categoryID: 1,
    key: "1-3-excel2pdf",
    name: "ExcelToPDF",
    path: "/ExcelToPDF",
    domain: "excel2pdf",
    icon: "icon_excel_to_pdf.svg",
    beforeToolIcon: "icon_excel_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#FFB700",
    inputFileType: ["xls", "xlsx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        default: "Excel to PDF",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert Excel files to PDF files quickly and directly.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Excel to PDF",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert your Excel spreadsheet to PDF",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert PPT", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  {
    ID: 7,
    categoryID: 1,
    key: "1-2-ppt2pdf",
    name: "PPTToPDF",
    path: "/PPTToPDF",
    domain: "ppt2pdf",
    icon: "icon_ppt_to_pdf.svg",
    beforeToolIcon: "icon_ppt_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#FF8000",
    inputFileType: ["ppt", "pptx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.ppt2pdf.name",
        default: "PPT to PDF",
      },
      description: {
        key: "tool.ppt2pdf.description",
        default: "Convert PPT files to PDF files quickly and dirctly.",
      },
      title: {
        key: "tool.ppt2pdf.title",
        default: "PPT to PDF",
      },
      subtitle: {
        key: "tool.ppt2pdf.subtitle",
        default: "Convert your PowerPoint to PDF",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert PPT", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.ppt2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.ppt2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.ppt2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.ppt2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.ppt2pdf.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  {
    ID: 8,
    categoryID: 2,
    key: "2-1-pdfreader",
    name: "PDFReader",
    path: "/PDFReader",
    domain: "pdfreader",
    icon: "icon_reader_new.svg",
    beforeToolIcon: "icon_reader_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdfreader.name",
        default: "PDF Reader",
      },
      description: {
        key: "tool.pdfreader.description",
        default: "Can open PDF files quickly,not stuck,easy to read.",
      },
      title: {
        key: "tool.pdfreader.title",
        default: "PDF Reader",
      },
      subtitle: {
        key: "tool.pdfreader.subtitle",
        default: "Display, print, and share PDFs online",
      },
      tip: {
        title: {
          key: "tool.pdfreader.tipTitle",
          default: ["How to", "use", "reader"],
        },
        body: [
          {
            title: {
              key: "tool.pdfreader.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdfreader.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdfreader.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdfreader.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdfreader.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  {
    ID: 9,
    categoryID: 2,
    key: "2-6-deletepdf",
    name: "DeletePDF",
    path: "/DeletePDF",
    domain: "deletepdf",
    icon: "icon_delete_new.svg",
    beforeToolIcon: "icon_delete_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.deletepdf.name",
        default: "Delete PDF",
      },
      description: {
        key: "tool.deletepdf.description",
        default: "Quickly delete one or more pages from your PDF.",
      },
      title: {
        key: "tool.deletepdf.title",
        default: "Delete PDF Pages",
      },
      subtitle: {
        key: "tool.deletepdf.subtitle",
        default: "Remove pages from your PDF online",
      },
      tip: {
        title: {
          key: "tool.deletepdf.tipTitle",
          default: ["How to", "delete", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.deletepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.deletepdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.deletepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.deletepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.deletepdf.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },

  {
    ID: 10,
    categoryID: 2,
    key: "2-5-rotatepdf",
    name: "RotatePDF",
    path: "/RotatePDF",
    domain: "rotatepdf",
    icon: "icon_rotate_new.svg",
    beforeToolIcon: "icon_rotate_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.rotatepdf.name",
        default: "Rotate PDF",
      },
      description: {
        key: "tool.rotatepdf.description",
        default: "Quickly realize PDF single page,or entire PDF rotation.",
      },
      title: {
        key: "tool.rotatepdf.title",
        default: "Rotate PDF",
      },
      subtitle: {
        key: "tool.rotatepdf.subtitle",
        default: "Realize PDF single page or entire PDF rotation",
      },
      tip: {
        title: {
          key: "tool.rotatepdf.tipTitle",
          default: ["How to", "rotate", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.rotatepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.rotatepdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.rotatepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.rotatepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.rotatepdf.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  {
    ID: 11,
    categoryID: 0,
    key: "0-1-pdf2img",
    name: "PDFToJPG",
    path: "/PDFToJPG",
    domain: "pdf2img",
    icon: "icon_pdf_to_image.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_img.svg",
    color: "#FFB700",
    inputFileType: ["pdf"],
    outputFileType: ["jpg", "zip"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2img.name",
        default: "PDF to JPG",
      },
      description: {
        key: "tool.pdf2img.description",
        default: "Convert PDF files to images quickly and dirctly.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Convert PDF to JPG",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Extract images or save each page from PDF to JPG",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "convert PDF", "to JPG"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },
  {
    ID: 12,
    categoryID: 2,
    key: "2-0-editorpdf",
    name: "PDFEditor",
    path: "/PDFEditor",
    domain: "editorpdf",
    icon: "icon_editor.svg",
    beforeToolIcon: "icon_editor.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.editorpdf.name",
        default: "PDF Editor",
      },
      description: {
        key: "tool.editorpdf.description",
        default: "Add text highlights shapes images and signature to your pdf.",
      },
      title: {
        key: "tool.editorpdf.title",
        default: "PDF Editor",
      },
      subtitle: {
        key: "tool.editorpdf.subtitle",
        default: "The best online tool to edit PDF documents",
      },
      tip: {
        title: {
          key: "tool.editorpdf.tipTitle",
          default: ["How to", "edit PDF", "online"],
        },
        body: [
          {
            title: {
              key: "tool.editorpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.editorpdf.tip1.text",
              default: "Drag and drop your PDF document into the PDF Editor.",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.editorpdf.tip2.text",
              default:
                "Add text, images, shapes or freehand annotations as you wish.You can also edit the size, font, and color of the added content.",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.editorpdf.tip3.text",
              default:
                "Click share button or download button to save the change pdf.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.editorpdf.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },

];
export const extraTool = [
  {
    key: "ex-0-moretool",
    name: "MoreTool",
    path: "/",
    domain: "",
    icon: "icon_more_tool.svg",
    context: {
      name: {
        key: "tool.moretool.name",
        default: "More tools",
      },
    },
  },
];
// 模板列表
export const templates = [
  {
    toolName: "Legal Contract",
    path: "/LegalContract",
    EventCategroy: "LegalContract",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe:
      "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "LegalContract.svg",
    fill: "#FC9A9A",
  },
  {
    toolName: "Chart Vector",
    path: "/ChartVector",
    EventCategroy: "ChartVector",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe:
      "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "ChartVector.svg",
    fill: "#FFCB00",
  },
  {
    toolName: "PDF/A Document",
    path: "/PDFADocument",
    EventCategroy: "PDFADocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe:
      "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFADocument.svg",
    fill: "#82D8FF",
  },
  {
    toolName: "PDF/A Document",
    path: "/PDFBDocument",
    EventCategroy: "PDFBDocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe:
      "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFBDocument.svg",
    fill: "#A25DDC",
  },
];
export default { toolCategory, toolList, templates, extraTool };
// 工具ID
// 0  pdf2word
// 1  pdf2img
// 2  word2pdf
// 3  img2pdf
// 4  ppt2pdf
// 5  excel2pdf
// 6  editorpdf
// 7  pdfreader
// 8  compresspdf
// 9  splitpdf
// 10 mergepdf
// 11 rotatepdf
// 12 deletepdf
