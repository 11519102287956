export const subscribe = {
  syncSubPlanUrl: "/sub/getPlan",
  syncUserSubStateUrl: "/sub/subState",
  verifyUserSubIdUrl: "/sub/verifySub",
  suspendUserSubUrl: "/sub/suspend/",
  renewUserSubUrl: "/sub/renew/",
};
export const user = {
  loginUserUrl: "/user/login",
  forgetUserPasswordUrl: "/user/resetPassSubmit",
  saveResetUserPasswordUrl: "/user/updatePassword",
  saveResetUrl: "/user/resetPassSave",
  registerUserUrl: "/user/register",
  verifyLoginTokenUrl: "/user/profile",
};

export const convert = {
  createFileConvertJobUrl: "/convert/submit/pdf2office",
  checkConvertJobStateUrl: "/convert/state/",
  getConvertKeyUrl: "/convert/uploadurl/",
  CompressPDFConvertUrl: "/convert/submit/pdfCompress",
  createOfficeConversionUrl: "/convert/submit/office2pdf",
};

export const share = {
  getShareFileUrl: "/share/uploadurl",
  createShareFileUrl: "/share/createLink",
  getFileFromShareUrl: "/share/view",
};
export default {subscribe,user,convert,share};
