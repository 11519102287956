const toolPageInfo = {
    compresspdf: {
        toolName: "CompressPDF",
        fileType: ["pdf"],
        title: "Compress PDF",
        subtitle: "Reduce the size of your PDF online",
        subDomain: "compresspdf",
        path: "/CompressPDF",
        successful_title: "Compress PDF Successful",
    },
    deletepdf: {
        toolName: "DeletePDF",
        fileType: ["pdf"],
        title: "Delete PDF Pages",
        subtitle: "Remove pages from your PDF online",
        successful_title: "Delete PDF Pages Successful",
        path: "/DeletePDF",
        subDomain: "deletepdf"
    },
    excel2pdf: {
        toolName: "ExcelToPDF",
        fileType: ["xls", "xlsx"],
        title: "Excel to PDF",
        subtitle: "Convert your Excel spreadsheet to PDF",
        successful_title: "Excel to PDF Convert Successful",
        path: "/ExcelToPDF",
        subDomain: "excel2pdf"
    },
    img2pdf: {
        toolName: "ImageToPDF",
        fileType: ["avif", "cur", "jfif", "bmp", "gif", "jpg", "jpeg", "png", "svg", "webp"],
        title: "Image to PDF",
        subtitle: "The best web app to convert Image to PDF",
        path: "/ImageToPDF",
        successful_title: "Image to PDF Convert Successful",
        subDomain: "img2pdf"
    },
    mergepdf: {
        toolName: "MergePDF",
        fileType: ["pdf"],
        title: "Merge PDF",
        subtitle: "The easiest way to combine PDF Files",
        path: "/MergePDF",
        successful_title: "Merge PDF Successful",
        subDomain: "mergepdf"
    },
    editorpdf: {
        toolName: "PDFEdit",
        fileType: ["pdf"],
        title: "PDF Edit",
        subtitle: "The best online tool to edit PDF documents",
        successful_title: "PDF Edit Successful",
        path: "/PDFEditor",
        subDomain: "editorpdf"
    },
    pdfreader: {
        toolName: "PDFReader",
        fileType: ["pdf"],
        title: "PDF Reader",
        subtitle: "Display, print, and share PDFs online",
        successful_title: "PDF Reader",
        path: "/PDFReader",
        subDomain: "pdfreader"
    },
    pdf2img: {
        toolName: "PDFToJPG",
        fileType: ["pdf"],
        title: "Convert PDF to JPG",
        subtitle: "Extract images or save each page from PDF to JPG",
        successful_title: "PDF to JPG Convert Successful",
        path: "/PDFToJpg",
        subDomain: "pdf2img"
    },
    pdf2word: {
        toolName: "PDFToWord",
        fileType: ["pdf"],
        title: "PDF to Word Converter",
        subtitle: "Magically convert PDFs to editable Word files",
        path: "/PDFToWord",
        successful_title: "PDF to Word Convert Successful",
        subDomain: "pdf2word"
    },
    ppt2pdf: {
        toolName: "PPTToPDF",
        fileType: ["ppt", "pptx"],
        title: "PPT to PDF",
        subtitle: "Convert your PowerPoint to PDF",
        successful_title: "PPT to PDF Convert Successful",
        path: "/PPTToPDF",
        subDomain: "ppt2pdf"
    },
    rotatepdf: {
        toolName: "RotatePDF",
        fileType: ["pdf"],
        title: "Rotate PDF",
        subtitle: "Reduce the size of your PDF online",
        path: "/RotatePDF",
        successful_title: "Rotate PDF Successful",
        subDomain: "rotatepdf"
    },
    splitpdf: {
        // 文件上传信息
        toolName: "SplitPDF",
        fileType: ["pdf"],
        title: "Split PDF",
        subtitle: "An easy way to extract pages from PDF",
        path: "/SplitPDF",
        successful_title: "Split PDF Successful",
        subDomain: "splitpdf"
    },
    word2pdf: {
        // 文件上传信息
        toolName: "WordToPDF",
        fileType: ["doc", "docx"],
        title: "Word to PDF",
        subtitle: "Convert your Word to PDF",
        path: "/WordToPdf",
        subDomain: "word2pdf",
        successful_title: "Word to PDF Convert Successful",
    }

}
const toolPageInfoKey = {
    COMPRESSPDF: "compresspdf",
    DELETEPDF: "deletepdf",
    EXCEL2PDF: "excel2pdf",
    IMG2PDF: "img2pdf",
    MERGEPDF:"mergepdf",
    EDITORPDF:"editorpdf",
    PDFREADER:"pdfreader",
    PDF2IMG:"pdf2img",
    PDF2WORD:"pdf2word",
    PPT2PDF:"ppt2pdf",
    ROTATEPDF:"rotatepdf",
    SPLITPDF:"splitpdf",
    WORD2PDF:"word2pdf"
}

// 工具类别
export const toolCategory = [
    { ID: 0, name: "Convert to PDF" },
    { ID: 1, name: "Convert from PDF" },
    { ID: 2, name: "Edit PDF" },
  ];
  // 工具列表
  export const toolList = [
    {
      ID: 0,
      categoryID: 0,
      key: "0-0-pdf2word",
      name: "PDFToWord",
      path: "/PDFToWord",
      domain: "pdf2word",
      icon: "icon_pdf_to_word.svg",
      color: "#3D99F5",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.pdf2word.description",
          default: "Quickly convert PDF files to easily editable Word files.",
        },
        title: {
          key: "tool.pdf2word.title",
          default: "Merge PDF",
        },
        subtitle: {
          key: "tool.pdf2word.subtitle",
          default: "The easiest way to combine PDF Files",
        },
        tip: [
          {
            key: "tool.pdf2word.tipTitle",
            default: ["How to", "Convert a PDF Document", "to Word"],
          },
          {
            key: "tool.pdf2word.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.pdf2word.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.pdf2word.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 1,
      categoryID: 0,
      key: "0-1-pdf2img",
      name: "PDFToJPG",
      path: "/PDFToJPG",
      domain: "pdf2img",
      icon: "icon_pdf_to_image.svg",
      color: "#FFB700",
      inputFileType: ["pdf"],
      outputFileType: ["zip_jpg"],
      preview: "th",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.pdf2img.description",
          default: "Convert PDF files to images quickly and dirctly.",
        },
        title: {
          key: "tool.pdf2img.title",
          default: "Convert PDF to JPG",
        },
        subtitle: {
          key: "tool.pdf2img.subtitle",
          default: "Extract images or save each page from PDF to JPG",
        },
        tip: [
          {
            key: "tool.pdf2img.tipTitle",
            default: ["How to", "convert PDF", "to JPG"],
          },
          {
            key: "tool.pdf2img.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.pdf2img.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.pdf2img.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 2,
      categoryID: 1,
      key: "1-0-word2pdf",
      name: "WordToPdf",
      path: "/WordToPdf",
      domain: "word2pdf",
      icon: "icon_word_to_pdf.svg",
      color: "#3D99F5",
      inputFileType: ["doc", "docx"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.word2pdf.description",
          default: "Convert Word files to PDF files quickly and directly.",
        },
        title: {
          key: "tool.word2pdf.title",
          default: "Word to PDF",
        },
        subtitle: {
          key: "tool.word2pdf.subtitle",
          default: "Convert your Word to PDF",
        },
        tip: [
          {
            key: "tool.word2pdf.tipTitle",
            default: ["How to", "convert Word", "to PDF"],
          },
          {
            key: "tool.word2pdf.tip1",
            default:
              "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.word2pdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.pdf2img.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 3,
      categoryID: 1,
      key: "1-1-img2pdf",
      name: "ImageToPDF",
      path: "/ImageToPDF",
      domain: "img2pdf",
      icon: "icon_image_to_pdf.svg",
      color: "#FFB700",
      inputFileType: ["image"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.pdf2img.description",
          default: "Convert Image to PDF files quickly and directly.",
        },
        title: {
          key: "tool.pdf2img.title",
          default: "Image to PDF",
        },
        subtitle: {
          key: "tool.pdf2img.subtitle",
          default: "The best web app to convert Image to PDF",
        },
        tip: [
          {
            key: "tool.pdf2img.tipTitle",
            default: ["How to", "Convert Image", "to PDF"],
          },
          {
            key: "tool.pdf2img.tip1",
            default:
              "Upload steps: Upload Image files or drag and drop files to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.pdf2img.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.pdf2img.tip3",
            default:
              "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
          },
        ],
      },
    },
    {
      ID: 4,
      categoryID: 1,
      key: "1-2-ppt2pdf",
      name: "PPTToPDF",
      path: "/PPTToPDF",
      domain: "ppt2pdf",
      icon: "icon_ppt_to_pdf.svg",
      color: "#FF8000",
      inputFileType: ["ppt", "pptx"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.ppt2pdf.description",
          default: "Convert PPT files to PDF files quickly and dirctly.",
        },
        title: {
          key: "tool.ppt2pdf.title",
          default: "PPT to PDF",
        },
        subtitle: {
          key: "tool.ppt2pdf.subtitle",
          default: "Convert your PowerPoint to PDF",
        },
        tip: [
          {
            key: "tool.ppt2pdf.tipTitle",
            default: ["How to", "convert PPT", "to PDF"],
          },
          {
            key: "tool.ppt2pdf.tip1",
            default:
              "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.ppt2pdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.ppt2pdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 5,
      categoryID: 1,
      key: "1-3-excel2pdf",
      name: "ExcelToPDF",
      path: "/ExcelToPDF",
      domain: "excel2pdf",
      icon: "icon_excel_to_pdf.svg",
      color: "#FFB700",
      inputFileType: ["xls", "xlsx"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.excel2pdf.description",
          default: "Convert Excel files to PDF files quickly and directly.",
        },
        title: {
          key: "tool.excel2pdf.title",
          default: "Excel to PDF",
        },
        subtitle: {
          key: "tool.excel2pdf.subtitle",
          default: "Convert your Excel spreadsheet to PDF",
        },
        tip: [
          {
            key: "tool.excel2pdf.tipTitle",
            default: ["How to", "convert Excel", "to PDF"],
          },
          {
            key: "tool.excel2pdf.tip1",
            default:
              "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.excel2pdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.excel2pdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 6,
      categoryID: 2,
      key: "2-0-editorpdf",
      name: "PDFEditor",
      path: "/PDFEditor",
      domain: "editorpdf",
      icon: "icon_editor.svg",
      color: "#0FC0C5",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.editorpdf.description",
          default: "Add text highlights shapes images and signature to your pdf.",
        },
        title: {
          key: "tool.editorpdf.title",
          default: "PDF Editor",
        },
        subtitle: {
          key: "tool.editorpdf.subtitle",
          default: "The best online tool to edit PDF documents",
        },
        tip: [
          {
            key: "tool.editorpdf.tipTitle",
            default: ["How to", "edit PDF", "online"],
          },
          {
            key: "tool.editorpdf.tip1",
            default: "Drag and drop your PDF document into the PDF Editor.",
          },
          {
            key: "tool.editorpdf.tip2",
            default:
              "Add text, images, shapes or freehand annotations as you wish.You can also edit the size, font, and color of the added content.",
          },
          {
            key: "tool.editorpdf.tip3",
            default:
              "Click share button or download button to save the change pdf.",
          },
        ],
      },
    },
    {
      ID: 7,
      categoryID: 2,
      key: "2-1-pdfreader",
      name: "PDFReader",
      path: "/PDFReader",
      domain: "pdfreader",
      icon: "icon_reader_new.svg",
      color: "#0FC0C5",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.pdfreader.description",
          default: "Can open PDF files quickly,not stuck,easy to read.",
        },
        title: {
          key: "tool.pdfreader.title",
          default: "PDF Reader",
        },
        subtitle: {
          key: "tool.pdfreader.subtitle",
          default: "Display, print, and share PDFs online",
        },
        tip: [
          {
            key: "tool.pdfreader.tipTitle",
            default: ["How to", "use", "reader"],
          },
          {
            key: "tool.pdfreader.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.pdfreader.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.pdfreader.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 8,
      categoryID: 2,
      key: "2-2-compresspdf",
      name: "CompressPDF",
      path: "/CompressPDF",
      domain: "compresspdf",
      icon: "icon_compress_pdf.svg",
      color: "#F23030",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.compresspdf.description",
          default: "Qulickly and dirtly reduce PDF file size.",
        },
        title: {
          key: "tool.compresspdf.title",
          default: "Compress PDF",
        },
        subtitle: {
          key: "tool.compresspdf.subtitle",
          default: "Reduce the size of your PDF online",
        },
        tip: [
          {
            key: "tool.compresspdf.tipTitle",
            default: ["How to", "compress", "PDF"],
          },
          {
            key: "tool.compresspdf.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.compresspdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.compresspdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 9,
      categoryID: 2,
      key: "2-3-splitpdf",
      name: "SplitPDF",
      path: "/SplitPDF",
      domain: "splitpdf",
      icon: "icon_split.svg",
      color: "#7961F2",
      inputFileType: ["pdf"],
      outputFileType: ["pdf", "zip_pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.splitpdf.description",
          default: "Quickly and directly split a PDF into multiple PDF files.",
        },
        title: {
          key: "tool.splitpdf.title",
          default: "Split PDF",
        },
        subtitle: {
          key: "tool.splitpdf.subtitle",
          default: "An easy way to extract pages from PDF",
        },
        tip: [
          {
            key: "tool.splitpdf.tipTitle",
            default: ["How to", "split", "PDF"],
          },
          {
            key: "tool.splitpdf.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.splitpdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.splitpdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 10,
      categoryID: 2,
      key: "2-4-mergepdf",
      name: "MergePDF",
      path: "/MergePDF",
      domain: "mergepdf",
      icon: "icon_merge.svg",
      color: "#7961F2",
      inputFileType: ["pdf"],
      outputFileType: ["pdf", "zip_pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.mergepdf.description",
          default:
            "Can quickly and directly merge multiple PDFs into one PDF file.",
        },
        title: {
          key: "tool.mergepdf.title",
          default: "Merge PDF",
        },
        subtitle: {
          key: "tool.mergepdf.subtitle",
          default: "The easiest way to combine PDF Files",
        },
        tip: [
          {
            key: "tool.mergepdf.tipTitle",
            default: ["How to", "merge", "PDF"],
          },
          {
            key: "tool.mergepdf.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.mergepdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.mergepdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 11,
      categoryID: 2,
      key: "2-5-rotatepdf",
      name: "RotatePDF",
      path: "/RotatePDF",
      domain: "rotatepdf",
      icon: "icon_rotate_new.svg",
      color: "#0FC0C5",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.rotatepdf.description",
          default: "Quickly realize PDF single page,or entire PDF rotation.",
        },
        title: {
          key: "tool.rotatepdf.title",
          default: "Rotate PDF",
        },
        subtitle: {
          key: "tool.rotatepdf.subtitle",
          default: "Realize PDF single page or entire PDF rotation",
        },
        tip: [
          {
            key: "tool.rotatepdf.tipTitle",
            default: ["How to", "rotate", "PDF"],
          },
          {
            key: "tool.rotatepdf.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.rotatepdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.rotatepdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
    {
      ID: 12,
      categoryID: 2,
      key: "2-6-deletepdf",
      name: "DeletePDF",
      path: "/DeletePDF",
      domain: "deletepdf",
      icon: "icon_delete_new.svg",
      color: "#0FC0C5",
      inputFileType: ["pdf"],
      outputFileType: ["pdf"],
      preview: "WebViwer",
      recommendFunction: [],
      context: {
        description: {
          key: "tool.deletepdf.description",
          default: "Quickly delete one or more pages from your PDF.",
        },
        title: {
          key: "tool.deletepdf.title",
          default: "Delete PDF Pages",
        },
        subtitle: {
          key: "tool.deletepdf.subtitle",
          default: "Remove pages from your PDF online",
        },
        tip: [
          {
            key: "tool.deletepdf.tipTitle",
            default: ["How to", "delete", "PDF"],
          },
          {
            key: "tool.deletepdf.tip1",
            default:
              "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
          },
          {
            key: "tool.deletepdf.tip2",
            default:
              "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
          },
          {
            key: "tool.deletepdf.tip3",
            default:
              "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
          },
        ],
      },
    },
  ];
  // 模板列表
  export const templates = [
    {
      toolName: "Legal Contract",
      path: "/LegalContract",
      EventCategroy: "LegalContract",
      domain: "templates",
      isNew: false,
      isImage: false,
      selectDescribe:
        "Upload steps: Just click on the PDF or drag and drop to upload and then ",
      beforeToolIcon: "LegalContract.svg",
      fill: "#FC9A9A",
    },
    {
      toolName: "Chart Vector",
      path: "/ChartVector",
      EventCategroy: "ChartVector",
      domain: "templates",
      isNew: false,
      isImage: false,
      selectDescribe:
        "Upload steps: Just click on the PDF or drag and drop to upload and then ",
      beforeToolIcon: "ChartVector.svg",
      fill: "#FFCB00",
    },
    {
      toolName: "PDF/A Document",
      path: "/PDFADocument",
      EventCategroy: "PDFADocument",
      domain: "templates",
      isNew: false,
      isImage: false,
      selectDescribe:
        "Upload steps: Just click on the PDF or drag and drop to upload and then ",
      beforeToolIcon: "PDFADocument.svg",
      fill: "#82D8FF",
    },
    {
      toolName: "PDF/A Document",
      path: "/PDFBDocument",
      EventCategroy: "PDFBDocument",
      domain: "templates",
      isNew: false,
      isImage: false,
      selectDescribe:
        "Upload steps: Just click on the PDF or drag and drop to upload and then ",
      beforeToolIcon: "PDFBDocument.svg",
      fill: "#A25DDC",
    },
  ];

export  {toolPageInfo,toolPageInfoKey}