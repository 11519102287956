// 全局状态管理
// 功能引入
import { createContext, useEffect, useState } from "react";
import {
  syncServerSubscriptionPlan,
  syncUserSubscriptionState,
} from "../api/subscribe";
// hook 工具,跨域存储
import { useCrossStorage } from "./hookTools";
// 国际化组件,定位用户语言并完成初始化
import { loadLocale } from "../internationalization/internationalization";
// 获取用户免费使用次数有效期(过期后重置次数)
const FREE_TIMES_EFFECTIVE =
  process.env.REACT_APP_FREE_TIMES_EFFECTIVE || 72000000;
// 创建 Context 对象
const GlobalContext = createContext();
// 封装 ContextProvider
function GlobalContextProvider({ children }) {
  // 国际化组件加载状态
  const [languageReady, setLanguageReady] = useState(false);
  // 创建全局状态
  // 用户名
  const [userName, setUserName, getUserName] = useCrossStorage(
    "user_name",
    null
  );
  // 用户登录凭证
  const [userToken, setUserToken, getUserToken] = useCrossStorage(
    "user_token",
    null
  );
  // 用户订阅状态
  const [userSubState, setUserSubState, getUserSubState] = useCrossStorage(
    "user_sub_state",
    null
  );
  // 用户订阅计划
  const [userSubPlan, setUserSubPlan, getUserSubPlan] = useCrossStorage(
    "user_sub_plan",
    null
  );
  // 用户免费使用次数
  const [freeTimes, setFreeTimes, getFreeTimes] = useCrossStorage(
    "free_times",
    null
  );
  // 老用户标识
  const [oldUser, setOldUser, getOldUser] = useCrossStorage("old_user", null);
  // 上传文件超过1G提示
  const [modelOpen, setModelOpen] = useState(false);
  // 上传文件错误提示
  const [fileEncryption, setFileEncryption] = useState(false);
  // 上传文件错误提示信息
  const [snackInfo, setSnackInfo] = useState({
    title: "File is locked",
    message:
      "Sorry,We're temporarily unable to manipulate the encrypted files.",
  });
  // 用户选择上传的文件
  const [uploadFile, setUploadFiles, getUploadFiles] = useCrossStorage(
    "user_file",
    null
  );
  // 用户即将下载的文件(处理后的文件)
  const [downloadFile, setDownloadFile, getDownloadFile] = useCrossStorage(
    "download_file",
    null
  );
  // 用户最后访问的页面
  const [lastReferrer, setLastReferrer, getLastReferrer] = useCrossStorage(
    "lastReferrer",
    { previousPage: "/", currentPage: "/" }
  );
  // 用户当前使用工具的信息
  const [informationConvert, setInformationConvert, getInformationConvert] =
    useCrossStorage("informationConvert", null);
  //存储结果页用户操作后的数据
  const [resultPageData, setResultPageData, getResultPageData] = useCrossStorage(
    "resultPageData",
    null,
  );

  // 存储文件是否是加密文件
  const [isEncrypt, setFileIsEncrypt, getFileIsEncrypt] = useCrossStorage(
    "isEncrypt",
    null
  );
  // 用户语言
  const [language, setLanguage, getLanguage] = useCrossStorage(
    "user_language",
    null
  );
  //用户选择文件的次数
  const [selectFileCount, setSelectFileCount] = useCrossStorage(
    'select_file_count',
    0
  )
  //用户成功上传文件的次数
  const [uploadFileSuccessCount, setUploadFileSuccessCount] = useCrossStorage(
    'upload_file_count',
    0
  )
  // 模式选项
  // Split页面
  const optionsModeList = ["Extract Pages", "Split"];
  const workStates = [
    "no file",
    "uploading",
    "uploaded",
    "stateName...",
    "Unlock",
    "preview",
  ];
  const [workState, setWorkState] = useState(workStates[0]);
  // 工具页当前工作状态
  //用户上传的文件列表
  const [userUploadFileList, setUserUploadFileList] = useState([]);
  //选择文件的页面列表
  const [userPageList, setUserPageList] = useState([]);
  //pdf分割模式
  // 1）按照选择页面提取单个PDF(Extract Pages)
  // 2）按页面拆分多个PDF(Split)
  const [splitOptionModeSelect, setSplitOptionModeSelect] = useState(
    optionsModeList[0]
  );
  //全选状态
  const [selectedAllState, setSelectedAllState] = useState(false);
  // Merge页面
  // 在此定义工具页工作状态
  const optionModeList = ["File mode", "Page mode"];
  // 模式选项
  const [mergeOptionModeSelect, setMergeOptionModeSelect] = useState(
    optionModeList[1]
  );
  const mergeWorkSpaceState = {
    workState,
    optionModeList,
    workStates,
    setWorkState,
    userUploadFileList,
    setUserUploadFileList,
    userPageList,
    setUserPageList,
    mergeOptionModeSelect,
    setMergeOptionModeSelect,
  };
  // 旋转PDF
  const rotateWorkSpaceState = {
    workState,
    workStates,
    setWorkState,
    userUploadFileList,
    setUserUploadFileList,
    userPageList,
    setUserPageList,
  };
  // 删除和分割
  const workSpaceState = {
    workState,
    optionsModeList,
    workStates,
    setWorkState,
    userUploadFileList,
    setUserUploadFileList,
    userPageList,
    setUserPageList,
    splitOptionModeSelect,
    setSplitOptionModeSelect,
    selectedAllState,
    setSelectedAllState,
  };
  // 保存全局状态
  const globalState = {
    // 用户名,即用户邮箱
    userName,
    getUserName,
    setUserName,
    // 用户登录凭证
    userToken,
    getUserToken,
    setUserToken,
    // 用户订阅状态
    userSubState,
    getUserSubState,
    setUserSubState,
    // 用户订阅计划
    userSubPlan,
    getUserSubPlan,
    setUserSubPlan,
    // 用户免费使用次数
    freeTimes,
    getFreeTimes,
    setFreeTimes,
    // 老用户标识
    oldUser,
    getOldUser,
    setOldUser,
    modelOpen,
    setModelOpen,
    fileEncryption,
    setFileEncryption,
    snackInfo,
    setSnackInfo,
    // 用户选择上传的文件
    uploadFile,
    getUploadFiles,
    setUploadFiles,
    // 用户即将下载的文件
    downloadFile,
    getDownloadFile,
    setDownloadFile,
    workSpaceState,
    mergeWorkSpaceState,
    rotateWorkSpaceState,
    // 用户最后访问的页面
    lastReferrer,
    setLastReferrer,
    getLastReferrer,
    // 用户当前使用工具信息
    informationConvert,
    setInformationConvert,
    getInformationConvert,
    getFileIsEncrypt,
    setFileIsEncrypt,
    isEncrypt,
    //存储结果页用户操作后的数据
    resultPageData,
    setResultPageData,
    getResultPageData,
    // 用户语言
    language,
    setLanguage,
    getLanguage,
    // 用户选择文件次数
    selectFileCount,
    setSelectFileCount,
    //用户成功上传文件的次数
    uploadFileSuccessCount,
    setUploadFileSuccessCount
  };
  // 页面初始化
  useEffect(() => {
    (async () => {
      // 查看用户订阅计划是否缓存,如果没有则通过服务器获取用户订阅计划
      const subPlanCache = await getUserSubPlan();
      logi("subPlanCache:", JSON.stringify(subPlanCache));
      if (!subPlanCache) {
        const serverSubPlan = await syncServerSubscriptionPlan();
        if (serverSubPlan.status === "success") {
          setUserSubPlan(serverSubPlan.response);
        }
      }
      // 查看用户免费次数是否缓存,如果超时或没有缓存则重置用户免费次数
      const freeTimesCache = await getFreeTimes();
      logi("freeTimesCache:", JSON.stringify(freeTimesCache));
      const nowTime = new Date().getTime();
      if (
        freeTimesCache &&
        freeTimesCache.expiration &&
        freeTimesCache.createTime
      ) {
        const nowDate = new Date().getDate();
        const createDate = new Date(freeTimesCache.createTime).getDate();
        const isExpiration =
          nowTime - FREE_TIMES_EFFECTIVE > freeTimesCache.expiration ||
          createDate != nowDate;
        if (isExpiration) {
          await setFreeTimes({
            createTime: nowTime,
            expiration: nowTime + FREE_TIMES_EFFECTIVE,
            times: 0,
          });
        }
      } else {
        await setFreeTimes({
          createTime: nowTime,
          expiration: nowTime + FREE_TIMES_EFFECTIVE,
          times: 0,
        });
      }
      const lastReferrerCache = await getLastReferrer();
      const currentPage = window.location.pathname;
      const previousPage = lastReferrerCache?.currentPage
        ? lastReferrerCache.currentPage
        : "/";
      await setLastReferrer({ previousPage, currentPage });
      log("lastReferrer", { previousPage, currentPage });
      // 等待国际化组件初始化
      await loadLocale();
      setLanguageReady(true);
    })();
  }, []);
  //当 userToken 发生变化的时候,即用户登录登出时,重新设置用户属性
  useEffect(() => {
    (async () => {
      // 获取当前浏览器可见区域的宽高
      const windowWidth = window.document.documentElement.clientWidth;
      const windowHeight = window.document.documentElement.clientHeight;
      // 查看用户登录凭证是否缓存
      const userTokenCache = await getUserToken();
      if (userTokenCache) {
        // 同步用户订阅状态
        const userSub = await syncUserSubscriptionState({
          userToken: userTokenCache,
        });
        // 同步成功保存用户订阅状态
        if (userSub.status === "success" && userSub.response) {
          setUserSubState(userSub.response);
        }
        // 根据用户订阅状态设置不同用户属性
        if (userSub?.response.status === "ACTIVE") {
          setConstructEventUserProperties({
            user_vip_state: userSub.response.billCycle,
            screen_size: windowWidth + "*" + windowHeight,
          });
        } else {
          setConstructEventUserProperties({
            user_vip_state: "NONE",
            screen_size: windowWidth + "*" + windowHeight,
          });
        }
      } else {
        setConstructEventUserProperties({
          user_vip_state: "NONE",
          screen_size: windowWidth + "*" + windowHeight,
        });
      }
    })();
  }, [userToken]);
  // 发布全局状态
  return (
    <>
      {languageReady && (
        <GlobalContext.Provider value={globalState}>
          {children}
        </GlobalContext.Provider>
      )}
    </>
  );
}
// 抛出方法
// 在被GlobalContextProvider包裹的组件中通过引入GlobalContext获取全局状态
// GlobalContextProvider会向其包裹的子组件内部发布全局状态
export { GlobalContext, GlobalContextProvider };
